import React, { SyntheticEvent, useEffect, useRef, useState} from 'react';
import {frycooks,bg,fryCookHeader,video,fryCookText} from '../styles/layout/frycooks/index.module.scss'
import vid from '../images/dumpster.mp4';
import {inputContainer, inputBox,input, checkBtn,resultsContainer,contextText, resultText, red, green} from '../styles/layout/checkredeemed/index.module.scss';

declare global {
    interface Window { 
    Web3: any;
    ethereum: any
    web3: any
    }
  }


const REDEEMED_STATES = {
    true: "true",
    false: "false",
    none: "none",
    error: "error"
}
const VALID_STATES = {
    true: "true",
    false: "false"
}

const FryCooks = ()=> {

    const videoRef = useRef<HTMLVideoElement>(null);
    
    const play = ()=> {
      videoRef.current?.play();
    };

    const [search, setSearch] = useState("");
    const [result, setResult] = useState({redeemed: REDEEMED_STATES.none, valid: VALID_STATES.true});

    const updateSearch = (e:React.ChangeEvent<HTMLInputElement>)=>{
        setSearch(e.currentTarget.value)
        setResult({redeemed: REDEEMED_STATES.none, valid: VALID_STATES.true})
    }

    const runSearch = () => {

      if(isNaN(search as any)){
        setResult({redeemed:REDEEMED_STATES.false, valid: VALID_STATES.false})
      }else{

        fetch(`https://us-central1-sudoburger-9b96e.cloudfunctions.net/app/cookcheck/${search}`)
        .then(result => result.json())
        .then(result => {console.log(result, search);
             setResult({redeemed:result.isRedeemed, valid: result.isValid,})})
        .catch(_ => setResult({redeemed:REDEEMED_STATES.error, valid: VALID_STATES.true}))
      }

    }

    useEffect(()=>{
       play();
    },[]);

    return (
        <>
        <div className={bg}>
        <video
                 ref={videoRef}
                 className={video}
                 loop
                 muted={true}
                 playsInline={true}
               >
                <source src={vid} type="video/mp4" />
               </video>         </div>
        <div className={frycooks}>
            <h2 className={fryCookHeader}>  Check if  redeemed</h2>
            <p className={fryCookText}>BUYING A FRYCOOK ON SECONDARY? USE THIS TOOL TO
DOUBLE CHECK IF THE FRYCOOK HAS BEEN REDEEMED FOR 
INGREDIENTS YET</p>
           <div className={inputContainer}>
               <div className={inputBox}>
                    <label>FRYCOOK#</label>
                    <input className={input} type='text' value={search} onChange={updateSearch} placeholder='cook #'/>
               </div>
               <button className={checkBtn} onClick={runSearch}>check</button>
          </div>  
         <>
         <div className={resultsContainer}>
             {
                result.valid === VALID_STATES.false ? 
                  <>
                  <p className={resultText}>Cook not minted or doesn't exist, check your search and try again</p>
                  </>
                : 
                <>
                {
               result.redeemed === REDEEMED_STATES.error && <>
             <p className={resultText}>Could not load data for cook check query and try again</p>
             </>
             }
             {
               result.redeemed === REDEEMED_STATES.true && <>
                <p className={resultText}>FryCook {search} <span className={green}>Redeemable</span></p>
             <p className={contextText}>YOU CAN USE THIS COOK TO MINT FREE INGREDIENTS!</p>
             </>
             }
             {
               result.redeemed === REDEEMED_STATES.false && <>
                <p className={resultText}>FryCook {search} <span className={red}>Redeemed</span></p>
             <p className={contextText}>HIS COOK HAS ALREADY BEEN REDEEMED BY A PREVIOUS OWNER.
IF YOU BUY THIS COOK YOU WILL NOT BE ABLE TO REDEEM
2 FREE INGREDIENTS WITH IT. </p>
             </>
             }</>
             }
             
          </div>
         </>
      </div>
            

        </>
    );
};

export default FryCooks;